import React from 'react';


export const Loader = () => {
  return (
      <div className="loading">
      <div class="lds-ellipsis">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}
